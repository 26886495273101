import { Dialog, Transition } from "@headlessui/react";
import { DragEvent, Fragment } from "react";
import Icon from "../icons/icon";
import { ClassProps } from "@/lib/types/class-props";
import Close from "../icons/lib/close";

interface ModalProps extends ClassProps {
  title?: string;
  children: React.ReactNode;
  open: boolean;
  onDismiss?: () => void;
  onClose?: () => void;
  size?: string;
  isClosable?: boolean;
  isDismissable?: boolean;
  setFile?: React.Dispatch<React.SetStateAction<File | null>>;
  setIsDragging?: React.Dispatch<React.SetStateAction<boolean>>;
  type?: "fileUpload" | null;
}

export default function Modal({
  title,
  children,
  open,
  onDismiss,
  onClose,
  size,
  isClosable = true,
  isDismissable = true,
  className,
  setFile,
  setIsDragging = () => {},
  type,
}: ModalProps) {
  const isFileUploadModal = type === "fileUpload";
  function dropHandler(ev: DragEvent<HTMLDivElement>) {
    if (!setFile) {
      return;
    }
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      [...ev.dataTransfer.items].forEach((item) => {
        // If dropped items aren't files, reject them
        if (item.kind === "file" && setFile) {
          const file = item.getAsFile();
          setFile(file);
        }
      });
    }
  }
  function dragOverHandler(ev: DragEvent<HTMLDivElement>) {
    if (!setFile) {
      return;
    }
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
    setIsDragging(true);
  }
  return isFileUploadModal ? (
    <div onDrop={(e) => dropHandler(e)} onDragOver={dragOverHandler}>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[2000]"
          onClose={() => isDismissable && onDismiss?.()}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`w-full transform overflow-hidden rounded-2xl bg-white py-6 px-10 text-left align-middle shadow-xl transition-all ${
                    size ?? "max-w-5xl"
                  } ${className}`}
                >
                  {title && (
                    <Dialog.Title
                      as="h1"
                      className="text-4xl font-evolventa font-bold text-gray-900 text-center py-6"
                    >
                      {title}
                    </Dialog.Title>
                  )}
                  <div className="mt-2">
                    {/* times icon */}
                    {isClosable && (
                      <a className="cursor-pointer" onClick={onClose}>
                        <Icon
                          icon={Close}
                          className="absolute top-5 right-5 lg:top-10 lg:right-10 w-6 h-6 lg:w-9 lg:h-9"
                          color="gray"
                        />
                      </a>
                    )}
                    {children}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  ) : (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[2000]"
        onClose={() => isDismissable && onDismiss?.()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`w-full transform overflow-hidden rounded-2xl bg-white py-6 px-10 text-left align-middle shadow-xl transition-all ${
                  size ?? "max-w-5xl"
                } ${className}`}
              >
                {title && (
                  <Dialog.Title
                    as="h1"
                    className="text-4xl font-evolventa font-bold text-gray-900 text-center py-6"
                  >
                    {title}
                  </Dialog.Title>
                )}
                <div className="mt-2">
                  {/* times icon */}
                  {isClosable && (
                    <a className="cursor-pointer" onClick={onClose}>
                      <Icon
                        icon={Close}
                        className="absolute top-5 right-5 lg:top-10 lg:right-10 w-6 h-6 lg:w-9 lg:h-9"
                        color="gray"
                      />
                    </a>
                  )}
                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
