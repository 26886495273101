import { ClassProps } from "@/lib/types/class-props";
import { cx } from "@/lib/utils/className.utils";
import React, { PropsWithChildren } from "react";

export type SkeletonTextProps = PropsWithChildren & ClassProps;

/**
 * This component is a wrapper around text. It aims to display a skeleton
 * on an existing text.
 */
const SkeletonText = ({ className, children }: SkeletonTextProps) => {
  return (
    <div className={cx("relative", className)}>
      {children}
      <span className="absolute inset-0 animate-pulse bg-gray/40 rounded-lg"></span>
    </div>
  );
};

export default SkeletonText;
