import { BaseEntityType } from "../models/enums";
import {
  Beat,
  FileType,
  SoundKit,
  Artist,
  GenericResponse,
} from "../models/models";
import api from "./api-config";
import { mapBeatJsonToBeat } from "./beats-manager";
import { mapSongJsonToBeat } from "./songs-manager";
import { mapSoundKitJsonToSoundKit } from "./soundkits-manager";
import { DetailEntity } from "./types/audiolab";
import { isBeat, isSong, isSoundkit, isArtist } from "./helpers";
import { getCategoryStringFromBaseEntityType } from "./utils/audiolab/getCategoryStringFromBaseEntityType";
import { SearchConfig } from "./types/search/search-config";

// Automatic info generation endpoints
export const startProcessingBeat = async (
  id: string,
  token: string,
  force: boolean = false
) => {
  const prefix = force ? "force-" : "";
  const res = await api.get(`/audiolab/${prefix}load-beat/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

export const startProcessingSong = async (
  id: string,
  token: string,
  force: boolean = false
) => {
  const prefix = force ? "force-" : "";
  const res = await api.get(`/audiolab/${prefix}load-song/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

export const startProcessingSoundkit = async (
  id: string,
  token: string,
  force: boolean = false
) => {
  const prefix = force ? "force-" : "";
  const res = await api.get(`/audiolab/${prefix}load-soundkit/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

export const startProcessingArtist = async (
  id: string,
  token: string,
  force: boolean = false
) => {
  const prefix = force ? "force-" : "";
  const res = await api.get(`/audiolab/${prefix}load-artist/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

/**
 * Performs a GET call to the API according to the type of the item.
 *
 * @param item is the item to process
 * @param token the access token
 * @returns the data from the call
 */
export const getLoadEntity = async (
  item: Beat | SoundKit,
  token: string,
  force: boolean = false
) => {
  if (isSoundkit(item)) {
    return await startProcessingSoundkit(item.id, token, force);
  } else if (isSong(item)) {
    return await startProcessingSong(item.id, token, force);
  } else if (isBeat(item)) {
    return await startProcessingBeat((item as Beat).id, token, force);
  } else if (isArtist(item)) {
    return await startProcessingArtist((item as Artist).id, token, force);
  }
};

/**
 * Performs a GET call to the API to load an artists from Audiolab.
 *
 * @param artist is the artist to load
 * @param token the access token of the user
 * @param force if it should be called by force
 * @returns the data from the call
 */
export const getLoadArtist = async (
  artist: Artist,
  token: string,
  force = false
) => {
  return await startProcessingArtist(artist.id, token, force);
};

export const getIsRadioEditGenerated = async (
  id: string,
  type: BaseEntityType
) => {
  try {
    const suffix =
      process.env.NEXT_PUBLIC_API_URL === "https://api.licenselounge.com"
        ? ""
        : "-test";
    const bucket = `licenselounge-${getCategoryStringFromBaseEntityType(
      type
    )}${suffix}`;
    const key = `${id}/radio.png`;
    const res = await api.get(`audiolab/read?bucket=${bucket}&key=${key}`);

    return res.data && res.data.status === "success";
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getRadioEditUrlsAsync = async (
  id: string,
  type: BaseEntityType,
  token: string
) => {
  try {
    const res = await api.post(
      `/audiolab/get-radio-edit-urls/${id}/${type}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const startGenerateRadioEditAsync = async (
  id: string,
  type: BaseEntityType,
  token: string
) => {
  try {
    const res = await api.post(
      `/audiolab/generate-radio-edit/${id}/${type}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const startRadioEditGenerationAsync = async (
  id: string,
  type: BaseEntityType,
  token: string
) => {
  try {
    const res = await api.post(`/audiolab/radio-edit/${id}/${type}`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export type AudiolabStatus = {
  name: string;
  status: string;
};

export const getStatus = async (
  id: string,
  type: BaseEntityType,
  token: string
) => {
  try {
    const res = await api.get<AudiolabStatus[]>(
      `/audiolab/status/${id}/${type}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

// Main Audiolab + Audiolab detail endpoints
export const getAudiolabEntityDetail = async (
  id: string,
  type: BaseEntityType,
  token: string
) => {
  const itemJson = (
    await api.get<DetailEntity>(`/audiolab/detail-entity/${id}/${type}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).data;

  return { item: itemJson };
};

export const getAll = async (token: string, payload: AllTrackRequest) => {
  const res = await api.post(`audiolab/search`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const results = res.data.map((res: any) => {
    if (!res.is_royalty_free && res.bpm !== undefined && !res.type) {
      return mapBeatJsonToBeat(res);
    } else if (res.type === BaseEntityType.song) {
      return mapSongJsonToBeat(res);
    } else {
      return mapSoundKitJsonToSoundKit(res);
    }
  });

  return { results: results as Beat[] | SoundKit[] };
};

export const getBeats = async (token: string, payload: AllTrackRequest) => {
  try {
    const res = await api.post(`audiolab/beats/search`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const results = res.data.map((res: any) => {
      return mapBeatJsonToBeat(res);
    });

    return { results: results as Beat[] };
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getSongs = async (token: string, payload: AllTrackRequest) => {
  const res = await api.post(`audiolab/songs/search`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const results = res.data.map((res: any) => {
    return mapSongJsonToBeat(res);
  });

  return { results: results as Beat[] };
};

export const getSoundkits = async (token: string, payload: AllTrackRequest) => {
  const res = await api.post(`audiolab/soundkits/search`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const results = res.data.map((res: any) => {
    return mapSoundKitJsonToSoundKit(res);
  });

  return { results: results as SoundKit[] };
};

export const deleteItemAsync = async (
  id: string,
  type: BaseEntityType,
  token: string
) => {
  try {
    const res = await api.post(
      `/audiolab/remove`,
      {
        entity_id: id,
        type: type,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return { beat: mapBeatJsonToBeat(res.data) };
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const exportStemAsync = async (
  token: string,
  id: string,
  format: string,
  name: string,
  itemType: BaseEntityType
) => {
  const res = await api.post(
    `/audiolab/export`,
    {
      entity_id: id,
      format: format,
      file_name: name,
      type: itemType,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (res.data.status === 400) {
    throw new Error();
  }

  if (res.data) {
    return { file: res.data.file };
  } else {
    return { file: null };
  }
};

export const getAudiolabBeatsCount = async (
  token: string,
  payload: Pick<SearchConfig, "filter">
) => {
  const resp = await api.post(`/audiolab/beats/count`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (resp.data) {
    return resp.data;
  }
};

export const getAudiolabSongsCount = async (
  token: string,
  payload: Pick<SearchConfig, "filter">
) => {
  const resp = await api.post(`/audiolab/songs/count`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (resp.data) {
    return resp.data;
  }
};

export const getAudiolabSoundsCount = async (
  token: string,
  payload: Pick<SearchConfig, "filter">
) => {
  const resp = await api.post(`/audiolab/soundkits/count`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (resp.data) {
    return resp.data;
  }
};

export type CoverArtParams = {
  id: string;
  type: BaseEntityType;
};

export type SelectCoverArtParams = { item: number } & CoverArtParams;

// Cover art API calls
export const postSelectCoverArt = async (
  token: string,
  payload: SelectCoverArtParams
) => {
  try {
    const resp = await api.post(
      `/audiolab/select-cover-art/${payload.id}/${payload.type}/${payload.item}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (resp.data) {
      return resp.data;
    }

    return null;
  } catch (error) {
    console.error(`postSelectCoverArt: ${error}`);
    throw error;
  }
};

export type CoverArtStatusReturn = {
  status: "initial" | "inprogress" | "complete";
};

export const getCoverArtStatus = async (
  token: string,
  payload: CoverArtParams
) => {
  try {
    const resp = await api.post<CoverArtStatusReturn>(
      `/audiolab/status-cover-art/${payload.id}/${payload.type}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (resp.data) {
      return resp.data;
    }

    return null;
  } catch (error) {
    console.error(`postSelectCoverArt: ${error}`);
    throw error;
  }
};

export type GetCoverArtsReturn = { files: FileType[] };

export const getCoverArts = async (token: string, payload: CoverArtParams) => {
  try {
    const resp = await api.post<GetCoverArtsReturn>(
      `/audiolab/get-cover-art/${payload.id}/${payload.type}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (resp.data) {
      return resp.data;
    }

    return null;
  } catch (error) {
    console.error(`getCoverArts: ${error}`);
    throw error;
  }
};

export const getCoverArtsJson = async (
  token: string,
  payload: CoverArtParams
) => {
  try {
    const resp = await api.post<GetCoverArtsReturn>(
      `/audiolab/get-cover-art-json/${payload.id}/${payload.type}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (resp.data) {
      return resp.data;
    }

    return null;
  } catch (error) {
    console.error(`getCoverArtsJson: ${error}`);
    throw error;
  }
};

export const postResetCoverArts = async (
  token: string,
  payload: CoverArtParams
) => {
  try {
    const resp = await api.post<CoverArtStatusReturn>(
      `/audiolab/reset-cover-art/${payload.id}/${payload.type}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (resp.data) {
      return resp.data;
    }

    return null;
  } catch (error) {
    console.error(`postResetCoverArts: ${error}`);
    throw error;
  }
};

export type PostGenerateCoverArtParams = {
  body?: { prompt?: string };
} & CoverArtParams;

export const postGenerateCoverArt = async (
  token: string,
  payload: PostGenerateCoverArtParams
) => {
  try {
    const resp = await api.post<CoverArtStatusReturn>(
      `/audiolab/generate-cover-art/${payload.id}/${payload.type}`,
      payload.body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (resp.data) {
      return resp.data;
    }

    return null;
  } catch (error) {
    console.error(`postGenerateCoverArt: ${error}`);
    throw error;
  }
};

/**
 * Triggers the entity duration task for the given type and id.
 *
 * @param type is the type of the entity, one of beat | song
 * @param id is the id of the entity
 * @returns the response as a {@link GenericResponse}
 */
export const getUpdateEntityDuration = async (
  type: "beat" | "song",
  id: string
) => {
  try {
    const response = await api.get<GenericResponse>(
      `/audiolab/update-entity-duration/${type}/${id}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export type AllTrackRequest = {
  filter: {
    id: string | null;
    name: string | null;
    statuses: number[] | null;
    entity_types: number[];
  };
  sorting: {
    sort_isAlphabetical: boolean | null;
    sort_isNewest: boolean | null;
    sort_BPM: boolean | null;
  };
  limit: number | null;
  offset: number | null;
  includeAll?: boolean;
};

// Generated by https://quicktype.io

export interface AudiolabCountRequest {
  filter: {
    statuses: number[];
    name?: string;
  };
}

export const fetchAssets = async (
  token: string,
  payload: AllTrackRequest
): Promise<(Beat | SoundKit)[]> => {
  const res = await api.post(`audiolab/search`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const results = res.data.map((res: any) => {
    if (!res.is_royalty_free && res.bpm !== undefined && !res.type) {
      return mapBeatJsonToBeat(res);
    } else if (res.type === BaseEntityType.song) {
      return mapSongJsonToBeat(res);
    } else {
      return mapSoundKitJsonToSoundKit(res);
    }
  });

  return results;
};
