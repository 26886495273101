export enum Size {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
  free = "free",
}

export enum FilterType {
  licenseType = "licenseType",
  stemsType = "stems",
  rightsType = "rightsType",
  trackType = "trackType",
  recencyType = "recencyType",
  moodsType = "moodsType",
  keyType = "keyType",
  genreType = "genreType",
  styleType = "styleType",
  priceType = "priceType",
  bpmType = "bpmType",
  artistType = "artistType",
  featuredType = "featuredType",
  kindsType = "kindType",
  durationType = "durationType",
  vocalsType = "vocalsType",
}

export enum FilterOrder {
  licenseType = 1,
  rightsType = 2,
  bpmType = 3,
  keyType = 4,
  priceType = 5,
  genreType = 6,
  styleType = 7,
  moodsType = 8,
  recencyType = 9,
  durationType = 10,
  vocalsType = 11,
}

export enum SortOptions {
  random = "Random",
  new = "Newest",
  old = "Oldest",
  cheap = "Lowest Price",
  expensive = "Highest Price",
  az = "Sort A to Z",
  za = "Sort Z to A",
  featured = "Featured",
  lowBpm = "Bpm - Low to High",
  highBpm = "Bpm - High to Low",
}

export enum Categories {
  beats = "Beats",
  sounds = "Sounds",
  songs = "Songs",
  creators = "Creators",
}

export enum AudioType {
  kit = "sounds",
  track = "beats",
}

export enum Category {
  BEATS,
  SOUNDKITS,
  ARTISTS,
}

export const ListViews = ["sounds", "beats"] as const;
export type ListViews = typeof ListViews[number];

export enum ItemStatus {
  Published = 0,
  Pending = 1,
  Draft = 2,
  Private = 3,
  Purchased = 4,
  Processing = 5,
  Deleted = 6,
}

export const allItemStatusExceptDeleted = [
  ItemStatus.Published,
  ItemStatus.Pending,
  ItemStatus.Draft,
  ItemStatus.Private,
  ItemStatus.Purchased,
  ItemStatus.Processing,
];

export enum LicenseRight {
  Any = 0,
  "Exclusive" = 1,
  "Non-Exclusive" = 2,
  "Basic" = 3,
  "Pro" = 4,
  "Enterprise" = 5,
  "Buy-Out" = 6,
}

export enum LicenseType {
  Any = 0,
  Sync = 1,
  Recording = 2,
  SoundKits = 3,
}

export enum BaseEntityType {
  beat = 0,
  soundkit = 1,
  creator = 2,
  link = 3,
  song = 4,
}

export enum FileFormat {
  "MP3" = 0,
  "WAV" = 1,
  "STEMS + WAV" = 2,
  "ZIP" = 3,
}
