import { MAX_BPM, MAX_PRICE } from "../components/filter";
import {
  BaseEntityType,
  FilterType,
  ItemStatus,
  SortOptions,
} from "../models/enums";
import {
  Artist,
  Beat,
  FileType,
  Filter,
  LicenseRight,
  Mood,
  SubGenre,
} from "../models/models";
import api from "./api-config";
import { SearchConfig } from "./types/search/search-config";
import { SearchCountConfig } from "./types/search/search-count-config";
import { getSortingFromOption } from "./utils/sales-purchases/getSortingFromOption";
import { getFilterFromOptions } from "./utils/search/getFilterFromOptions";
import { getSortingFromOptions } from "./utils/search/getSortingFromOptions";

export const createBeatAsync = async (
  name: string,
  artistId: string,
  token: string
) => {
  const res = await api.post(
    `/beats`,
    {
      name: name,
      artist_id: artistId,
      bpm: 0,
      status: ItemStatus.Processing,
      unlimited_distribution: false,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return { beat: mapBeatJsonToBeat(res.data) };
};

export const getBeatsAsyncController = new AbortController();

export const getBeatsAsync = async (
  query?: string,
  appliedFilters?: Filter[],
  sort?: any,
  order?: boolean,
  limit?: number,
  offset?: number,
  status?: number,
  includeAll = false
): Promise<{ beats: Beat[] }> => {
  const filter = getFilterFromOptions({
    query,
    filters: appliedFilters,
    status,
  });
  const sorting = getSortingFromOption(sort);

  const beats = await getBeats({
    filter,
    sorting,
    limit,
    offset,
    includeAll,
  });
  return { beats };
};

export const getBeatsController = new AbortController();

/**
 * Perform a search to obtain beats by the given filters and sorting options.
 *
 * @param config the configuration for performing this search
 * @returns an array of {@link Beat}.
 */
export const getBeats = async ({
  filter,
  sorting,
  limit,
  offset,
  includeAll,
}: SearchConfig) => {
  const res = await api.post(
    "/beats/search",
    {
      filter,
      sorting: sorting ? sorting : getSortingFromOptions(),
      limit,
      offset,
      includeAll,
    },
    { signal: getBeatsController.signal }
  );

  return res.data?.map((beat: any) => mapBeatJsonToBeat(beat)) as Beat[];
};

export const getBeatsOptimizedController = new AbortController();

/**
 * Perform an optimized search to obtain beats by the given filters and sorting
 * options.
 *
 * @param config the configuration for performing this search
 * @returns an array of {@link Beat}.
 */
export const getBeatsOptimized = async ({
  filter,
  sorting,
  limit,
  offset,
  includeAll,
}: SearchConfig) => {
  const res = await api.post(
    "/beats/search/optimized",
    {
      filter,
      sorting: sorting ? sorting : getSortingFromOptions(),
      limit,
      offset,
      includeAll,
    },
    { signal: getBeatsOptimizedController.signal }
  );

  return res.data?.map((beat: any) => mapBeatJsonToBeat(beat)) as Beat[];
};

export const getAudiolabBeatsAsyncController = new AbortController();

export type GetAudiolabBeatsParams = { token?: string | null } & SearchConfig;

/**
 * Perform a search to obtain audiolab beats by the given filters and sorting
 * options.
 *
 * @param config the configuration for performing this search
 * @returns an array of {@link Beat}.
 */
export const getAudiolabBeats = async ({
  filter,
  sorting,
  limit,
  offset,
  includeAll,
  token,
}: GetAudiolabBeatsParams) => {
  const res = await api.post(
    "/audiolab/beats/search",
    {
      filter,
      sorting,
      limit,
      offset,
      includeAll,
    },
    {
      signal: getAudiolabBeatsAsyncController.signal,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return res.data?.map((beat: any) => mapBeatJsonToBeat(beat)) as Beat[];
};

export const getBeatsCountAsync = async (
  query?: string,
  appliedFilters?: Filter[]
) => {
  const genre = appliedFilters?.find(
    (filter) => filter.type === FilterType.genreType
  )?.id;
  const styles = appliedFilters
    ?.filter((filter) => filter.type === FilterType.styleType)
    .map((fil) => fil.id);
  const moods = appliedFilters
    ?.filter((filter) => filter.type === FilterType.moodsType)
    .map((fil) => fil.id);

  const keys = appliedFilters
    ?.filter((filter) => filter.type === FilterType.keyType)
    .map((fil) => fil.id);
  const date =
    parseInt(
      appliedFilters?.find((filter) => filter.type === FilterType.recencyType)
        ?.id!
    ) - 1;
  const license =
    parseInt(
      appliedFilters?.find((filter) => filter.type === FilterType.licenseType)
        ?.id!
    ) - 2;
  const rights = appliedFilters
    ?.filter((filter) => filter.type === FilterType.rightsType)
    .map((fil) => Number(fil.id) - 1);
  const artists = appliedFilters
    ?.filter((filter) => filter.type === FilterType.artistType)
    .map((fil) => fil.id);

  let priceFrom: number = 0;
  let priceTo: number = MAX_PRICE;
  appliedFilters?.find((filter) => {
    if (filter.type === FilterType.priceType) {
      priceFrom = parseInt(filter.name.replaceAll("$", "").split("-").at(0)!);
      priceTo = parseInt(filter.name.replaceAll("$", "").split("-").at(1)!);
    }
  });

  let bpmFrom: number = 0;
  let bpmTo: number = MAX_BPM;
  appliedFilters?.find((filter) => {
    if (filter.type === FilterType.bpmType) {
      bpmFrom = parseInt(filter.name.split("-").at(0)!);
      bpmTo = parseInt(filter.name.split("-").at(1)!);
    }
  });

  const res = await api.post("/beats/count", {
    filter: {
      name: query,
      genres: genre ? [genre] : null,
      sub_genres: styles?.length! > 0 ? styles : null,
      moods: moods?.length! > 0 ? moods : null,
      price_from: priceFrom === 0 && priceTo === MAX_PRICE ? null : priceFrom,
      price_to: priceTo === MAX_PRICE && priceFrom === 0 ? null : priceTo,
      bpm_from: bpmFrom === 0 && bpmTo === MAX_BPM ? null : bpmFrom,
      bpm_to: bpmTo === MAX_BPM && bpmFrom === 0 ? null : bpmTo,
      keys: keys?.length! > 0 ? keys : null,
      date_added: date,
      license_type: license,
      license_rights: rights,
      artist_ids: artists?.length! > 0 ? artists : null,
      status: 0,
    },
  });
  return { count: res.data };
};

/**
 * Counts how many elements are in DB with the given filters.
 *
 * @param config is the configuration params
 * @returns the count
 */
export const getBeatsCount = async ({ filter }: SearchCountConfig) => {
  const res = await api.post("/beats/count", {
    filter,
  });
  return { count: res.data };
};

export const getBeatByIdAsync = async (id: string) => {
  const beatJson = await (await api.get(`/beats/${id}`)).data;
  return { beat: mapBeatJsonToBeat(beatJson) };
};

export const updateBeatByIdAsync = async (
  id: string,
  payload: any,
  token: string
) => {
  try {
    const res = await api.put(`/beats/${id}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res) {
      return { beat: mapBeatJsonToBeat(res.data) };
    }
  } catch (e) {
    throw e;
  }
  return {};
};

export const mapBeatJsonToBeat = (beatJson: any): Beat => {
  return {
    id: beatJson.id,
    name: beatJson.name,
    cover: beatJson.pictureUrl,
    tempo: beatJson.bpm,
    duration: beatJson.duration,
    isMp3: beatJson.isMp3,
    price: parseFloat(beatJson.min_price),
    lyrics: beatJson.lyrics || null,
    sample: beatJson.audio_preview,
    vocals: beatJson.vocals || null,
    genres: beatJson.subGenres?.length > 0 && beatJson.subGenres[0]?.genre,
    styles: beatJson.subGenres?.map((sub: any) => {
      return {
        id: sub.id,
        name: sub.name,
        genreId: sub.genre_id,
        type: FilterType.styleType,
      } as SubGenre;
    }),
    moods: beatJson.moods?.map((mood: any) => {
      return {
        id: mood.id,
        name: mood.name,
        // relatedMoods: mood.relatedMoods,
        type: FilterType.moodsType,
      } as Mood;
    }),
    soundsLike: beatJson.soundslike?.map((sl: any) => sl.name) ?? [],
    keySignature: beatJson.key || null,
    artist:
      beatJson.artist &&
      ({
        id: beatJson.artist.id,
        name: beatJson.artist.name,
        firstName: beatJson.artist.firstName,
        lastName: beatJson.artist.lastName,
        bio: beatJson.artist.bio,
        createdAt: beatJson.artist.createdAt,
        profilePicUrl: beatJson.artist.profilePicUrl,
      } as Artist),
    licenseRights:
      beatJson.license_rights?.map((right: any) => {
        return {
          id: right.id,
          price: right.extra_price,
          unlimitedDistribution: right.unlimited_distribution ?? 50,
          monetizeMusic: right.monetize_music ?? 15,
          name: right.license_right,
          files:
            right.files?.map((file: any) => {
              return {
                id: file.id,
                type: file.type,
                extraPrice: file.extra_price,
              } as FileType;
            }) || null,
        } as LicenseRight;
      }) || null,
    waveForms: {
      desktopUrl: beatJson?.wave_forms?.desktop_url ?? null,
      mobileUrl: beatJson?.wave_forms?.mobile_url ?? null,
    },
    publishDate: beatJson?.published_date,
    status: beatJson?.status,
    type: "beats",
    files: beatJson?.files ?? [],
  } as Beat;
};
