import { CartProvider } from "@/src/contexts/CartContext";
import { SessionProvider } from "next-auth/react";
import React, { PropsWithChildren } from "react";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";
import { CookiesProvider } from "react-cookie";
import AudioPlayerLayoutWithContext from "./AudioPlayerLayout";

export type BaseProvidersProps = PropsWithChildren;

/**
 * Wrapper that use only the required context providers for basic functionality
 * of the app.
 *
 * This will exclude contexts such as filters, query, web services, etc., and the
 * aim is to have a lightweight for the homepage and other pages that don't
 * require complex functionality.
 */
const BaseProviders = ({ children }: BaseProvidersProps) => {
  const queryClient = new QueryClient();

  return (
    <SessionProvider refetchInterval={2 * 60}>
      <QueryClientProvider client={queryClient}>
        <CartProvider>
          <CookiesProvider>
            <AudioPlayerLayoutWithContext>
              {children}
            </AudioPlayerLayoutWithContext>
          </CookiesProvider>
        </CartProvider>
      </QueryClientProvider>
      <Toaster />
    </SessionProvider>
  );
};

export default BaseProviders;
