import React, { ButtonHTMLAttributes } from "react";

export type TransparentButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const TransparentButton = ({ className, ...props }: TransparentButtonProps) => {
  return (
    <button
      className={`bg-transparent outline-none text-white w-auto break-keep whitespace-nowrap px-5 hover:opacity-60 ${className}`}
      {...props}
    ></button>
  );
};

export default TransparentButton;
